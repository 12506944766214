var headerId = document.getElementById('header');
var sticky = headerId.offsetTop;

document.addEventListener('DOMContentLoaded', function() {
  window.onscroll = function() {
    if (window.pageYOffset > sticky) {
        header.classList.add("sticky");
      } else {
        header.classList.remove("sticky");
      }
  };
});